<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter justify-center items-center" style="background-image: url('https://portal-cdn.rapid.education/images/authorization-background-image.jpeg')">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div style="height:244px;"  slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter">
            <div style="height:244px;" class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto d-theme-dark-light-bg self-center">
              <img style="max-height:130px;width:100%;margin-top:60px" alt="login" class="mx-auto" src="https://app-cdn.rapidseminars.com/logos/VibrantBig.png">
            </div>
            <div style="height:244px;"  class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Authorizing with Panel</h4>
                </div>
                <vs-input class="w-full mb-6" disabled icon="icon icon-lock" icon-no-border icon-pack="feather" value="rapidseminars"/>
                <vs-input class="w-full mb-6" disabled icon="icon icon-user" icon-no-border icon-pack="feather" value="basic email billing sensitive"/>
                <p>Please wait one moment...</p>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>

// TODO: Fix styling with the authorization page. The top-to-bottom heights don't match the exterior boxes

export default {
  data() {
    return {};
  },
  computed: {},
  methods: {
    async authorizeApplication() {
      this.$vs.loading();

      // Check if the current request is a new request without a callback token from Portal
      if (this.$route.query.code === undefined) {
        this.$http
          .get('oauth/callback', {
            maxRedirects: 0,
          })
          .then(response => {

            this.$vs.loading.close();

            if (response.data && response.data.data.url) {

              // Set our Session Token from our initial `oauth/callback` request
              const payload = response.data.data;
              localStorage.setItem('sessionToken', payload.token);

              // Send them to the Portal to be authenticated
              location.href = payload.url;

              return this.$vs.notify({
                title: 'Authorizing with Panel',
                text: 'Redirecting you to the Rapid Education Panel.',
                color: 'success',
              });
            }

          })
          .catch(() => {
            // Exception is already sent up to Sentry via a Middleware and Vue Injection

            this.$vs.loading.close();

            return this.$vs.notify({
              title: 'Failed to Authorize',
              text: 'An error has occurred while authorizing you with Portal',
              color: 'danger',
            });
          });

        return;
      }

      // Extract the current URL
      const URL_PARAMS = new URLSearchParams(this.$route.query);

      this.$vs.loading();

      this.$http
        .get(`oauth/callback?${URL_PARAMS}`, {
          maxRedirects: 0,
        })
        .then(async response => {
          this.$vs.loading.close();

          if (response.data) {

            const payload = response.data.data;

            // If we received a valid session token from seminars servers, save the token into our storage.
            if (payload.token) {
              localStorage.setItem('sessionToken', payload.token);
              localStorage.setItem('userInfo', JSON.stringify(payload.user));
              setTimeout(() => {
                location.href = '/dashboard'
              }, 750)
            }

            return this.$vs.notify({
              title: 'Authorized with Panel',
              text: 'Redirecting you to the Seminars Panel...',
              color: 'success',
            });
          }

          return this.$vs.notify({
            title: 'Failed to Authorize',
            text: 'An unknown error has occurred while authorizing your profile with Portal.',
            color: 'danger',
          });
        })
        .catch(error => {
          // Exception is already sent up to Sentry via a Middleware and Vue Injection

          this.$vs.loading.close();

          // If the error was an HTTP error, it was most likely caused by a invalid session token or an other
          // server error so we should try the request again
          if (error.response) {
            localStorage.removeItem('sessionToken');
            this.$router.push('/authorize');
            return this.authorizeApplication();
          }

          return this.$vs.notify({
            title: 'Failed to Authorize',
            text: 'An unknown error has occurred while authorizing your profile with Portal.',
            color: 'danger',
          });
        });

    },
  },
  mounted() {
    this.authorizeApplication();
  },
};

</script>
